<script lang="ts">
	import { dev } from "$app/environment";
	import { faExclamation } from "@fortawesome/free-solid-svg-icons";
	import Fa from "svelte-fa";

	export let error: Error;

	function close() {
		document.getElementById("error-report")!.remove();
	}
</script>

<div class="space-y-4 rounded-lg bg-gray-800 px-4 py-2 shadow-md" style="max-height: 90vh; overflow-y: auto;">
	<section>
		<div class="flex items-center justify-center space-x-2">
			<Fa class="font-bold" icon={faExclamation} />
			<h1 class="border-b-0 text-2xl">An Unrecoverable Error Occurred</h1>
		</div>

		<div>{error.toString()}</div>
	</section>

	<pre class="rounded-lg bg-black p-2 text-left font-mono">
		{error.stack}
	</pre>

	<div class="flex justify-center space-x-2">
		<button
			class="rounded-lg bg-blue-400 px-4 py-2 font-bold text-white hover:bg-blue-300"
			on:click={() => {
				window.location.pathname = "/";
			}}
		>
			Go Home
		</button>

		<button
			class="rounded-lg bg-blue-700 px-4 py-2 font-bold text-white hover:bg-blue-600"
			on:click={(event) => (event.altKey && event.shiftKey ? close() : window.location.reload())}
		>
			Reload
		</button>

		{#if dev}
			<button class="rounded-lg bg-gray-700 px-4 py-2 font-bold text-white hover:bg-gray-600" on:click={close}>
				Close
			</button>
		{/if}
	</div>
</div>
