/* ****************
 * GENERATED CODE *
 **************** */

import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSyncType } from "../../system/HewSyncType";
import { HewSync, type Writeable } from "../../system/HewSync";
import { ProjectInstanceID, UserOrganizationID, HewSyncPath } from "../../system/IDs";

@HewSync.Type({
	name: "SynapseFile",
	scope: "Synapse",
	table: "user",
	type: "Synapse:File"
})
export class SynapseFile extends HewSyncType {
	public static readonly Type = SynapseFile as typeof HewSyncType;
	public static override readonly type = "SynapseFile";
	public override readonly type = SynapseFile.type;

	@HewSync.Field({})
	public readonly instance: ProjectInstanceID;

	@HewSync.Field({})
	public readonly name: string;

	@HewSync.Field({})
	public readonly organization: UserOrganizationID;

	@HewSync.Field({})
	public readonly path: HewSyncPath;

	@HewSync.Field({})
	public readonly config: any;

	@HewSync.Field({})
	public readonly fileType: string;

	@HewSync.Field({})
	public readonly metadata: any;

	@HewSync.Field({})
	public readonly shareable: boolean;

	@HewSync.Field({})
	public readonly size: string;

	@HewSync.Field({})
	public readonly status: string;

	@HewSync.Field({})
	public readonly uuid: string;

	@HewSync.Field({})
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({})
	public readonly updatedAt: HewSync.Timestamp;

	/********************************************/

	public static get(ids: SynapseFile.IDs) {
		this.initSubscriptions();

		let key = this.getKey(ids);
		let item = this.cache.get(key);

		if (item === undefined) {
			item = new SynapseFile({
				...SynapseFile.defaults(),
				instance: ids.instance,
				name: ids.name,
				organization: ids.organization,
				path: ids.path
			});
			this.cache.set(key, item);
			item.fetch(item.ids);
		}

		return item;
	}

	public static rawGet(ids: SynapseFile.IDs) {
		return HewSync.get<SynapseFile>(SynapseFile.Type, ids);
	}

	public static list(/*...*/) {
		// TODO
	}

	public static listBy(params?: Partial<SynapseFile.IDs>): HewSyncList<SynapseFile> {
		let list = new (HewSyncList as any)(SynapseFile.Type, undefined, params);
		return list;
	}

	public static rawList(inputs: Partial<SynapseFile.IDs> & { limit?: number; nextToken?: string }) {
		return HewSync.list<SynapseFile>(SynapseFile.Type, inputs);
	}

	public static batchGet(ids: SynapseFile.IDs[]) {
		return HewSync.batchGet<SynapseFile>(SynapseFile.Type, ids);
	}

	public static create(values: SynapseFile.Create) {
		return HewSync.create<SynapseFile>(SynapseFile.Type, "create", values);
	}

	public static update(
		ids: SynapseFile.IDs,
		values: Partial<Pick<SynapseFile, SynapseFile.Modifiable>>,
		target?: SynapseFile
	) {
		return HewSync.request<SynapseFile>(SynapseFile.Type, "update", { ...values, ...ids }, target);
	}

	public static remove(ids: SynapseFile.IDs, target?: SynapseFile) {
		return HewSync.request<SynapseFile>(SynapseFile.Type, "remove", ids, target);
	}

	public static duplicate(ids: SynapseFile.IDs, target: { path: string[]; name: string }) {
		return HewSync.create<SynapseFile>(SynapseFile.Type, "duplicate", {
			...ids,
			targetPath: target.path,
			targetName: target.name
		});
	}

	public static updateKey(ids: SynapseFile.IDs, target: { path: string[]; name: string }) {
		return HewSync.request<SynapseFile>(SynapseFile.Type, "updateKey", {
			...ids,
			targetPath: target.path,
			targetName: target.name
		});
	}

	public static download(values: SynapseFile.Download) {
		return HewSync.request<SynapseFile, string>(SynapseFile.Type, "download", values);
	}

	public static preview(values: SynapseFile.Preview) {
		return HewSync.request<SynapseFile, string>(SynapseFile.Type, "preview", values);
	}

	public static upload(values: SynapseFile.Upload) {
		return HewSync.request<SynapseFile, string>(SynapseFile.Type, "upload", values);
	}

	/********************************************/

	public update(values: Partial<Pick<SynapseFile, SynapseFile.Modifiable>>) {
		return SynapseFile.update(this.ids, values, this);
	}

	public remove() {
		return SynapseFile.remove(this.ids, this);
	}

	public duplicate(target: { path: string[]; name: string }) {
		return SynapseFile.duplicate(this.ids, target);
	}

	public updateKey(target: { path: string[]; name: string }) {
		return SynapseFile.updateKey(this.ids, target);
	}

	public download(values: Omit<SynapseFile.Download, SynapseFile.IDNames>) {
		return SynapseFile.download({ ...this.ids, ...values });
	}

	public preview(values: Omit<SynapseFile.Preview, SynapseFile.IDNames>) {
		return SynapseFile.preview({ ...this.ids, ...values });
	}

	public upload(values: Omit<SynapseFile.Upload, SynapseFile.IDNames>) {
		return SynapseFile.upload({ ...this.ids, ...values });
	}

	/********************************************/

	private static cache = new Map<string, SynapseFile>();

	public get ids() {
		return { organization: this.organization, instance: this.instance, path: this.path, name: this.name };
	}

	public static getKey(ids: SynapseFile.IDs) {
		return (
			ids.organization.toString() +
			"/" +
			ids.instance.toString() +
			"/" +
			ids.path.toString() +
			"/" +
			ids.name.toString()
		);
	}

	public get key() {
		return (
			this.organization.toString() +
			"/" +
			this.instance.toString() +
			"/" +
			this.path.toString() +
			"/" +
			this.name.toString()
		);
	}

	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).instance = data.instance;
		(this as any).name = data.name;
		(this as any).organization = data.organization;
		(this as any).path = data.path;

		this.apply({ ...SynapseFile.defaults(), ...data });
	}

	protected override apply(data: Partial<SynapseFile.Fields>) {
		(this as any).config = data.config;
		(this as any).fileType = data.fileType;
		(this as any).metadata = data.metadata;
		(this as any).shareable = data.shareable;
		(this as any).size = data.size;
		(this as any).status = data.status;
		(this as any).uuid = data.uuid;
		(this as any).createdAt = data.createdAt;
		(this as any).updatedAt = data.updatedAt;
		this.onUpdate.execute(this);
	}

	protected static defaults() {
		let values = {} as SynapseFile;
		(values as any).config = {};
		(values as any).fileType = "";
		(values as any).metadata = {};
		(values as any).shareable = false;
		(values as any).size = undefined;
		(values as any).status = "pending";
		(values as any).uuid = undefined;
		(values as any).createdAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).updatedAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		return values;
	}

	protected static parse(data: any): Partial<SynapseFile.IDs & SynapseFile.Fields> {
		let output: Partial<Writeable<SynapseFile.IDs & SynapseFile.Fields>> = {};
		if (data.instance !== undefined && data.instance !== null) {
			output.instance = new ProjectInstanceID(data.instance);
		}
		if (data.name !== undefined && data.name !== null) {
			output.name = data.name;
		}
		if (data.organization !== undefined && data.organization !== null) {
			output.organization = new UserOrganizationID(data.organization);
		}
		if (data.path !== undefined && data.path !== null) {
			output.path = new HewSyncPath(data.path);
		}
		if (data.config !== undefined && data.config !== null) {
			output.config = data.config;
		}
		if (data.fileType !== undefined && data.fileType !== null) {
			output.fileType = data.fileType;
		}
		if (data.metadata !== undefined && data.metadata !== null) {
			output.metadata = data.metadata;
		}
		if (data.shareable !== undefined && data.shareable !== null) {
			output.shareable = data.shareable;
		}
		if (data.size !== undefined && data.size !== null) {
			output.size = data.size;
		}
		if (data.status !== undefined && data.status !== null) {
			output.status = data.status;
		}
		if (data.uuid !== undefined && data.uuid !== null) {
			output.uuid = data.uuid;
		}
		if (data.createdAt !== undefined && data.createdAt !== null) {
			output.createdAt = new HewSync.Timestamp(data.createdAt);
		}
		if (data.updatedAt !== undefined && data.updatedAt !== null) {
			output.updatedAt = new HewSync.Timestamp(data.updatedAt);
		}
		return output;
	}

	protected toJSON(): any {
		return {
			instance: this.instance,
			name: this.name,
			organization: this.organization,
			path: this.path,
			config: this.config,
			fileType: this.fileType,
			metadata: this.metadata,
			shareable: this.shareable,
			size: this.size,
			status: this.status,
			uuid: this.uuid,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt
		};
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this.Type, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}

	public static readonly fieldIDs = ["organization", "instance", "path", "name"] as const;
	public readonly fieldIDs = SynapseFile.fieldIDs;
	public static readonly fields = [
		"config",
		"fileType",
		"metadata",
		"shareable",
		"size",
		"status",
		"uuid",
		"createdAt",
		"updatedAt"
	] as const;
	public readonly fields = SynapseFile.fields;
}

export namespace SynapseFile {
	export type IDs = Pick<SynapseFile, SynapseFile.IDNames>;
	export type IDNames = "organization" | "instance" | "path" | "name";
	export type Fields = Pick<SynapseFile, SynapseFile.FieldNames>;
	export type FieldNames =
		| "config"
		| "fileType"
		| "metadata"
		| "shareable"
		| "size"
		| "status"
		| "uuid"
		| "createdAt"
		| "updatedAt";
	export type Modifiable = "config" | "fileType" | "metadata" | "shareable" | "size" | "status" | "uuid";
	export type Create = {
		instance: ProjectInstanceID;
		name: string;
		organization: UserOrganizationID;
		path: HewSyncPath;
		config?: any;
		fileType?: string;
		metadata?: any;
		shareable?: boolean;
		size?: string;
		status?: string;
		uuid?: string;
	};
	export type Download = {
		instance: ProjectInstanceID;
		name: string;
		organization: UserOrganizationID;
		path: HewSyncPath;
	};
	export type Preview = {
		instance: ProjectInstanceID;
		name: string;
		organization: UserOrganizationID;
		path: HewSyncPath;
	};
	export type Upload = {
		instance: ProjectInstanceID;
		name: string;
		organization: UserOrganizationID;
		path: HewSyncPath;
		contentType: string;
	};
}
