/* ****************
 * GENERATED CODE *
 **************** */

import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSyncType } from "../../system/HewSyncType";
import { HewSync, type Writeable } from "../../system/HewSync";
import { AdminAccountID } from "../../system/IDs";

@HewSync.Type({
	name: "AdminAccount",
	scope: "Admin",
	table: "admin",
	type: "Admin:Account"
})
export class AdminAccount extends HewSyncType {
	public static readonly Type = AdminAccount as typeof HewSyncType;
	public static override readonly type = "AdminAccount";
	public override readonly type = AdminAccount.type;

	@HewSync.Field({})
	public readonly account: AdminAccountID;

	@HewSync.Field({})
	public readonly email: string;

	@HewSync.Field({})
	public readonly name: string;

	@HewSync.Field({})
	public readonly lastEmailChangeAt: HewSync.Timestamp;

	@HewSync.Field({})
	public readonly lastLoginAt: HewSync.Timestamp;

	@HewSync.Field({})
	public readonly lastPasswordChangeAt: HewSync.Timestamp;

	@HewSync.Field({})
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({})
	public readonly updatedAt: HewSync.Timestamp;

	/********************************************/

	public static get(ids: AdminAccount.IDs) {
		this.initSubscriptions();

		let key = this.getKey(ids);
		let item = this.cache.get(key);

		if (item === undefined) {
			item = new AdminAccount({
				...AdminAccount.defaults(),
				account: ids.account
			});
			this.cache.set(key, item);
			item.fetch(item.ids);
		}

		return item;
	}

	public static rawGet(ids: AdminAccount.IDs) {
		return HewSync.get<AdminAccount>(AdminAccount.Type, ids);
	}

	public static list(/*...*/) {
		// TODO
	}

	public static listBy(params?: Partial<AdminAccount.IDs>): HewSyncList<AdminAccount> {
		let list = new (HewSyncList as any)(AdminAccount.Type, undefined, params);
		return list;
	}

	public static rawList(inputs: Partial<AdminAccount.IDs> & { limit?: number; nextToken?: string }) {
		return HewSync.list<AdminAccount>(AdminAccount.Type, inputs);
	}

	public static batchGet(ids: AdminAccount.IDs[]) {
		return HewSync.batchGet<AdminAccount>(AdminAccount.Type, ids);
	}

	public static create(values: AdminAccount.Create) {
		return HewSync.create<AdminAccount>(AdminAccount.Type, "create", values);
	}

	public static update(
		ids: AdminAccount.IDs,
		values: Partial<Pick<AdminAccount, AdminAccount.Modifiable>>,
		target?: AdminAccount
	) {
		return HewSync.request<AdminAccount>(AdminAccount.Type, "update", { ...values, ...ids }, target);
	}

	public static remove(ids: AdminAccount.IDs, target?: AdminAccount) {
		return HewSync.request<AdminAccount>(AdminAccount.Type, "remove", ids, target);
	}

	/********************************************/

	public update(values: Partial<Pick<AdminAccount, AdminAccount.Modifiable>>) {
		return AdminAccount.update(this.ids, values, this);
	}

	public remove() {
		return AdminAccount.remove(this.ids, this);
	}

	/********************************************/

	private static cache = new Map<string, AdminAccount>();

	public get ids() {
		return { account: this.account };
	}

	public static getKey(ids: AdminAccount.IDs) {
		return ids.account.toString();
	}

	public get key() {
		return this.account.toString();
	}

	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).account = data.account;

		this.apply({ ...AdminAccount.defaults(), ...data });
	}

	protected override apply(data: Partial<AdminAccount.Fields>) {
		(this as any).email = data.email;
		(this as any).name = data.name;
		(this as any).lastEmailChangeAt = data.lastEmailChangeAt;
		(this as any).lastLoginAt = data.lastLoginAt;
		(this as any).lastPasswordChangeAt = data.lastPasswordChangeAt;
		(this as any).createdAt = data.createdAt;
		(this as any).updatedAt = data.updatedAt;
		this.onUpdate.execute(this);
	}

	protected static defaults() {
		let values = {} as AdminAccount;
		(values as any).email = "";
		(values as any).name = "";
		(values as any).lastEmailChangeAt = undefined;
		(values as any).lastLoginAt = undefined;
		(values as any).lastPasswordChangeAt = undefined;
		(values as any).createdAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).updatedAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		return values;
	}

	protected static parse(data: any): Partial<AdminAccount.IDs & AdminAccount.Fields> {
		let output: Partial<Writeable<AdminAccount.IDs & AdminAccount.Fields>> = {};
		if (data.account !== undefined && data.account !== null) {
			output.account = new AdminAccountID(data.account);
		}
		if (data.email !== undefined && data.email !== null) {
			output.email = data.email;
		}
		if (data.name !== undefined && data.name !== null) {
			output.name = data.name;
		}
		if (data.lastEmailChangeAt !== undefined && data.lastEmailChangeAt !== null) {
			output.lastEmailChangeAt = new HewSync.Timestamp(data.lastEmailChangeAt);
		}
		if (data.lastLoginAt !== undefined && data.lastLoginAt !== null) {
			output.lastLoginAt = new HewSync.Timestamp(data.lastLoginAt);
		}
		if (data.lastPasswordChangeAt !== undefined && data.lastPasswordChangeAt !== null) {
			output.lastPasswordChangeAt = new HewSync.Timestamp(data.lastPasswordChangeAt);
		}
		if (data.createdAt !== undefined && data.createdAt !== null) {
			output.createdAt = new HewSync.Timestamp(data.createdAt);
		}
		if (data.updatedAt !== undefined && data.updatedAt !== null) {
			output.updatedAt = new HewSync.Timestamp(data.updatedAt);
		}
		return output;
	}

	protected toJSON(): any {
		return {
			account: this.account,
			email: this.email,
			name: this.name,
			lastEmailChangeAt: this.lastEmailChangeAt,
			lastLoginAt: this.lastLoginAt,
			lastPasswordChangeAt: this.lastPasswordChangeAt,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt
		};
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this.Type, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}

	public static readonly fieldIDs = ["account"] as const;
	public readonly fieldIDs = AdminAccount.fieldIDs;
	public static readonly fields = [
		"email",
		"name",
		"lastEmailChangeAt",
		"lastLoginAt",
		"lastPasswordChangeAt",
		"createdAt",
		"updatedAt"
	] as const;
	public readonly fields = AdminAccount.fields;
}

export namespace AdminAccount {
	export type IDs = Pick<AdminAccount, AdminAccount.IDNames>;
	export type IDNames = "account";
	export type Fields = Pick<AdminAccount, AdminAccount.FieldNames>;
	export type FieldNames =
		| "email"
		| "name"
		| "lastEmailChangeAt"
		| "lastLoginAt"
		| "lastPasswordChangeAt"
		| "createdAt"
		| "updatedAt";
	export type Modifiable = "email" | "name" | "lastEmailChangeAt" | "lastLoginAt" | "lastPasswordChangeAt";
	export type Create = {
		email: string;
		name: string;
		lastEmailChangeAt?: HewSync.Timestamp;
		lastLoginAt?: HewSync.Timestamp;
		lastPasswordChangeAt?: HewSync.Timestamp;
	};
}
