/* ****************
 * GENERATED CODE *
 **************** */

import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSyncType } from "../../system/HewSyncType";
import { HewSync, type Writeable } from "../../system/HewSync";
import { ProjectInstanceID, UserOrganizationID } from "../../system/IDs";

@HewSync.Type({
	name: "ProjectInstance",
	scope: "Project",
	table: "user",
	type: "Project:Instance"
})
export class ProjectInstance extends HewSyncType {
	public static readonly Type = ProjectInstance as typeof HewSyncType;
	public static override readonly type = "ProjectInstance";
	public override readonly type = ProjectInstance.type;

	@HewSync.Field({})
	public readonly instance: ProjectInstanceID;

	@HewSync.Field({})
	public readonly organization: UserOrganizationID;

	@HewSync.Field({})
	public readonly color: string;

	@HewSync.Field({})
	public readonly name: string;

	@HewSync.Field({})
	public readonly settings: any;

	@HewSync.Field({})
	public readonly template: string;

	@HewSync.Field({})
	public readonly version: string;

	@HewSync.Field({})
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({})
	public readonly updatedAt: HewSync.Timestamp;

	/********************************************/

	public static get(ids: ProjectInstance.IDs) {
		this.initSubscriptions();

		let key = this.getKey(ids);
		let item = this.cache.get(key);

		if (item === undefined) {
			item = new ProjectInstance({
				...ProjectInstance.defaults(),
				instance: ids.instance,
				organization: ids.organization
			});
			this.cache.set(key, item);
			item.fetch(item.ids);
		}

		return item;
	}

	public static rawGet(ids: ProjectInstance.IDs) {
		return HewSync.get<ProjectInstance>(ProjectInstance.Type, ids);
	}

	public static list(/*...*/) {
		// TODO
	}

	public static listBy(params?: Partial<ProjectInstance.IDs>): HewSyncList<ProjectInstance> {
		let list = new (HewSyncList as any)(ProjectInstance.Type, undefined, params);
		return list;
	}

	public static rawList(inputs: Partial<ProjectInstance.IDs> & { limit?: number; nextToken?: string }) {
		return HewSync.list<ProjectInstance>(ProjectInstance.Type, inputs);
	}

	public static batchGet(ids: ProjectInstance.IDs[]) {
		return HewSync.batchGet<ProjectInstance>(ProjectInstance.Type, ids);
	}

	public static create(values: ProjectInstance.Create) {
		return HewSync.create<ProjectInstance>(ProjectInstance.Type, "create", values);
	}

	public static update(
		ids: ProjectInstance.IDs,
		values: Partial<Pick<ProjectInstance, ProjectInstance.Modifiable>>,
		target?: ProjectInstance
	) {
		return HewSync.request<ProjectInstance>(ProjectInstance.Type, "update", { ...values, ...ids }, target);
	}

	public static remove(ids: ProjectInstance.IDs, target?: ProjectInstance) {
		return HewSync.request<ProjectInstance>(ProjectInstance.Type, "remove", ids, target);
	}

	/********************************************/

	public update(values: Partial<Pick<ProjectInstance, ProjectInstance.Modifiable>>) {
		return ProjectInstance.update(this.ids, values, this);
	}

	public remove() {
		return ProjectInstance.remove(this.ids, this);
	}

	/********************************************/

	private static cache = new Map<string, ProjectInstance>();

	public get ids() {
		return { organization: this.organization, instance: this.instance };
	}

	public static getKey(ids: ProjectInstance.IDs) {
		return ids.organization.toString() + "/" + ids.instance.toString();
	}

	public get key() {
		return this.organization.toString() + "/" + this.instance.toString();
	}

	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).instance = data.instance;
		(this as any).organization = data.organization;

		this.apply({ ...ProjectInstance.defaults(), ...data });
	}

	protected override apply(data: Partial<ProjectInstance.Fields>) {
		(this as any).color = data.color;
		(this as any).name = data.name;
		(this as any).settings = data.settings;
		(this as any).template = data.template;
		(this as any).version = data.version;
		(this as any).createdAt = data.createdAt;
		(this as any).updatedAt = data.updatedAt;
		this.onUpdate.execute(this);
	}

	protected static defaults() {
		let values = {} as ProjectInstance;
		(values as any).color = "";
		(values as any).name = "";
		(values as any).settings = {};
		(values as any).template = "";
		(values as any).version = "";
		(values as any).createdAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).updatedAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		return values;
	}

	protected static parse(data: any): Partial<ProjectInstance.IDs & ProjectInstance.Fields> {
		let output: Partial<Writeable<ProjectInstance.IDs & ProjectInstance.Fields>> = {};
		if (data.instance !== undefined && data.instance !== null) {
			output.instance = new ProjectInstanceID(data.instance);
		}
		if (data.organization !== undefined && data.organization !== null) {
			output.organization = new UserOrganizationID(data.organization);
		}
		if (data.color !== undefined && data.color !== null) {
			output.color = data.color;
		}
		if (data.name !== undefined && data.name !== null) {
			output.name = data.name;
		}
		if (data.settings !== undefined && data.settings !== null) {
			output.settings = data.settings;
		}
		if (data.template !== undefined && data.template !== null) {
			output.template = data.template;
		}
		if (data.version !== undefined && data.version !== null) {
			output.version = data.version;
		}
		if (data.createdAt !== undefined && data.createdAt !== null) {
			output.createdAt = new HewSync.Timestamp(data.createdAt);
		}
		if (data.updatedAt !== undefined && data.updatedAt !== null) {
			output.updatedAt = new HewSync.Timestamp(data.updatedAt);
		}
		return output;
	}

	protected toJSON(): any {
		return {
			instance: this.instance,
			organization: this.organization,
			color: this.color,
			name: this.name,
			settings: this.settings,
			template: this.template,
			version: this.version,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt
		};
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this.Type, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}

	public static readonly fieldIDs = ["organization", "instance"] as const;
	public readonly fieldIDs = ProjectInstance.fieldIDs;
	public static readonly fields = [
		"color",
		"name",
		"settings",
		"template",
		"version",
		"createdAt",
		"updatedAt"
	] as const;
	public readonly fields = ProjectInstance.fields;
}

export namespace ProjectInstance {
	export type IDs = Pick<ProjectInstance, ProjectInstance.IDNames>;
	export type IDNames = "organization" | "instance";
	export type Fields = Pick<ProjectInstance, ProjectInstance.FieldNames>;
	export type FieldNames = "color" | "name" | "settings" | "template" | "version" | "createdAt" | "updatedAt";
	export type Modifiable = "color" | "name" | "settings" | "template" | "version";
	export type Create = {
		organization: UserOrganizationID;
		color?: string;
		name: string;
		settings?: any;
		template: string;
		version: string;
	};
}
